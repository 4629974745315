@import '~@fluentui/react/dist/sass/References.scss';

.heroContainer {
  h1 {
    margin: 0;
    color: white;
    font-size: 2rem;
  }
  h2 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #FF5814;
  }
  p {
    font-size: 0.85rem;
    text-align: center;
    margin: 1rem 0;
    padding: 0 1rem;
  }
}

.hero {
  background: url('../../assets/hero-01.jpg') no-repeat center center;
  background-size: contain;
  width: 100%;
  aspect-ratio: 916 / 502;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
}

.gradient {
  background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 0 0 2rem;
  box-sizing: border-box;
}

@media (min-width:768px) {
  .heroContainer {
    h1 {
      font-size: 3rem;
    }
  }
}

@media (min-width:1280px) {
  .heroContainer {
    h1 {
      font-size: 4rem;
    }
  }
}