.Modal {
    background-color: white;
    width: 600px;
    min-height: 600px;
    border-radius: 25px;
}

.ImageGalleryModal {
    background-color: white;
    width: 600px;
    min-height: 600px;
    overflow-y: auto;
    border-radius: 25px;
    padding: 1rem;
}

.galleryItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.formActions {
    display: flex;
    flex-wrap: row;
    justify-content: space-between;
    margin-top: 1rem;
    grid-column: span 2;
}

.Overlay {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    background-color: hsl(0, 0%, 100%);
    border: 4px solid hsl(0, 0%, 90%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    fieldset,
    input,
    legend,
    select,
    textarea {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    input,
    select,
    textarea {
        border: 2px solid #333;
        background-color: white;
        border-radius: 0.25rem;
        font-size: 1.25rem;
        line-height: 1.5;
        font-family: sans-serif;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 0.25rem 0.5rem;
    }

    input[type="text"],
    input[type="email"],
    select,
    textarea {
        font-size: 1.25rem;
        line-height: 1.5;
        font-family: sans-serif;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 0.25rem 0.5rem;
    }

    textarea {
        min-height: 10rem;
    }

    .galleryFormItems {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
    }

    .fullWidth {
        grid-column: span 2;
    }
    .halfWidth {
        grid-column: span 1;
    }
} 
  
.galleryForm {
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    background-color: hsl(0, 0%, 100%);
    border: 4px solid hsl(0, 0%, 90%);

    fieldset,
    input,
    legend,
    select,
    textarea {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    input,
    select,
    textarea {
        border: 2px solid #333;
        background-color: white;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        line-height: 1;
        font-family: sans-serif;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 0.25rem 0.5rem;
    }

    input[type="text"],
    input[type="email"],
    select,
    textarea {
        font-size: 0.9rem;
        line-height: 1;
        font-family: sans-serif;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 0.25rem 0.5rem;
    }
    textarea {
        min-height: 10rem;
    }

    .galleryFormItems {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .fullWidth {
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .halfWidth {
        width: 50%;
        margin-bottom: 0.5rem;
    }
}

.largeLabel {
    display: block;
    font: bold 1rem sans-serif;
    margin-bottom: 0.25rem;
}

.submitButton {
    outline: none;
    appearance: none;
    border-color: rgb(255, 88, 20);
    font-size: 1rem;
    line-height: 1.25;
    font-family: sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100px;
    padding: 0.25rem 0.5rem;
    background-color: rgb(255, 88, 20);
    color: white;
    border-radius: 15px;
    cursor: pointer;
}

.closeButton {
    outline: none;
    appearance: none;
    border-color: #333;
    font-size: 1rem;
    line-height: 1.25;
    font-family: sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100px;
    padding: 0.25rem 0.5rem;
    background-color: #333;
    color: white;
    border-radius: 15px;
    cursor: pointer;
}