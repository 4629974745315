@import '~@fluentui/react/dist/sass/References.scss';

.header {
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(90deg,  rgba(137,0,120,1) 0%, rgba(206,5,105,1) 100%);
  color: white;
  position: relative;

  h3 {
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .editButton {
    display: none;
  }

  &:hover .editButton {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
