@import '~@fluentui/react/dist/sass/References.scss';

.heading {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
}

.links {
  img {
    width: 40px;
    height: auto;
    margin-right: 1rem;
  }
  a {
    text-decoration: none;
    color: black;
    margin: 0;

    &:hover {
      text-decoration: underline;
      color: black;
    }
  }
}

.link {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  .editButton {
    display: none;
  }

  &:hover .editButton {
    display: block;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}

.number {
  height: 40px;
  width: 40px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
}

@media (max-width: 767px) {
  .heading {
    display: none;
  }
  .links {
    display: none;
  }
}