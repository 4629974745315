@import '~@fluentui/react/dist/sass/References.scss';

.container {
  max-width: 1280px;
  margin: 0 auto;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  color: rgb(36, 36, 36);
}

.AvanadeHubSite {
  padding: 1em;
  color: "[theme:bodyText, default: #323130]";
  color: var(--bodyText);
  &.teams {
    font-family: $ms-font-family-fallbacks;
  }
}

.welcome {
  text-align: center;
}

.welcomeImage {
  width: 100%;
  max-width: 420px;
}

.links {
  a {
    text-decoration: none;
    color: "[theme:link, default:#03787c]";
    color: var(--link); // note: CSS Custom Properties support is limited to modern browsers only

    &:hover {
      text-decoration: underline;
      color: "[theme:linkHovered, default: #014446]";
      color: var(--linkHovered); // note: CSS Custom Properties support is limited to modern browsers only
    }
  }
}

/* grid container */
.rightSidebarGrid {
  display:grid;
  grid-template-areas:
      'header'
      'mainContent'
      'rightSidebar'
      'footer';
}

.rightSidebarGrid {
  margin-bottom: 2rem;
}

.rightSidebarGrid > * {
  padding: 0;
}

/* assign columns to grid areas */
.rightSidebarGrid > .header {
  grid-area:header;
  background:#f97171;
}
.rightSidebarGrid > .mainContent {
  grid-area:mainContent;
  background:#fff;
}
.rightSidebarGrid > .mainContent p {
  font-size: 0.85rem;
  text-align: center;
  margin: 2rem 0;
  padding: 0 1rem;
}
.rightSidebarGrid > .rightSidebar {
  padding-top: 2rem;
  padding-left: 2rem;
  grid-area: rightSidebar;
  background: white;
  min-width: 200px;
  width: 100%;
  overflow-x: hidden;
}
.rightSidebarGrid > .footer {
  grid-area:footer;
  background:#72c2f1;
}

.fpoImage {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.footerFpo {
  width: 100%;
  height: auto; 
}

.ourEvents {
  background-color: #F2F2F2;
  padding: 2rem 1rem;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  p {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.askAnExpert {
  margin-top: 4rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    text-decoration: underline;
  }

  h2 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 2rem;
  }
}

.expert {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  h5 {
    font-size: 0.7rem;
    margin: 0 0 0.5rem 0;
    line-height: 1;
  }

  p {
    font-size: 0.7rem;
    margin: 0;
    line-height: 1;
  }
}

.avatar {
  width: 50px;
  height: 50px;
  background-color: #F2F2F2;
  border-radius: 50%;
  margin-right: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.EventCoESVG {
  max-width: 70%;
  height: auto;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}


/* tablet breakpoint */
@media (min-width:768px) {
  .rightSidebarGrid {
      grid-template-columns:repeat(3, 1fr);
      grid-template-areas:
          'header header header'
          'mainContent mainContent rightSidebar'
          'footer footer footer';
  }
}
