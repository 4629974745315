.imageGallery {
  position: relative;

  a, a:visited {
    color: #FF5814;
  }

  .editButton {
    display: none;
  }

  &:hover .editButton {
    display: block;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}


.container {
  padding: 0 4rem;

  .arrow {
    background-color: #FF5814;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    outline: 0;
    appearance: none;

    svg {
      fill: white;
      width: 16px;
      height: 16px;
    }
  }
}

.react-slideshow-container+ul.indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 0;
}

.indicator {
  background-color: #CCCCCC;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 1rem;
  cursor: pointer;

  &.active {
    background-color: #FF5814 !important;
  }
}

.arrowPrev {
  left: -3rem !important;
}

.arrowNext {
  right: -3rem !important;
}

.eachSlideEffect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.eachSlideEffect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}