@import '~@fluentui/react/dist/sass/References.scss';

.heading {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
}

.linkContainer {
  display: flex;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;;
  flex-wrap: wrap;
  flex: 1;

  .link {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 17%;
    // height: 19%;
    // margin: 0.5%;
    border-style: solid;
    border-width: 2px;
    border-radius: 10%;
    margin: 1%;
    cursor: pointer;
    aspect-ratio: 1;
  }
  
  img {
    width: 40%;
    height: auto;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: black;
    margin: 0;
    font-size: 0.6rem;

    &:hover {
      text-decoration: underline;
      color: black;
    }
  }
}

.link {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}

.number {
  height: 40px;
  width: 40px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
}

@media (min-width:768px) {
  .links {
    a {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  }
}

@media (min-width:1200px) {
  .links {
    a {
      font-size: 0.8rem;
      margin-top: 1rem;
    }
  }
}